<template>
  <div class="lg:text-xl services home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div class="lg:mt-24 md:w-1/3 lg:w-1/2 bg-service absolute top-0 right-0 w-3/5 h-full mt-12 bg-left bg-cover">
      </div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Services</h2>
        <p class="lg:text-base text-sm">Home > <span class="text-sitePurple font-bold">Services</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto">
        <div class="lg:items-center flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-4">
              Plastic surgery and other aesthetic enhancements are becoming commonplace with many patients opting for an
              experienced surgeon for optimum results.
            </p>
            <p class="mb-8">
              With over 30 years of plastic surgery experience, Dr Marco is dedicated to helping patients achieve their
              aesthetic goals safely with natural-looking outcomes.
            </p>
            <a href="tel:+6564648075"
              class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">Consult Dr Marco today to
              find out more, give us a call at +65 6464 8075 now.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-face.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Face</h4>
                <p class="mb-4">
                  Facial concerns are common in many patients, as these are usually where signs of ageing are first
                  noticed. Dr Marco is well-equipped with the necessary tools, knowledge and techniques needed for a
                  rejuvenating facelift.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Some of the common face procedures that Dr Marco provides
                  are:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Facelift</li>
                  <li class="mb-1">Brow and Cheek Lift</li>
                  <li class="mb-1">Endoscopic Upper Brow Lift</li>
                  <li class="mb-1">Upper & Lower Eyelid Surgery</li>
                  <li class="mb-1">Cheek Augmentation</li>
                  <li class="mb-1">Chin Augmentation</li>
                  <li class="mb-1">Nose Surgery and Rhinoplasty</li>
                  <li class="mb-1">Injections – Dermal Fillers & Fat Grafting Injections</li>
                  <li class="mb-1">Ear Lobe Repairs</li>
                  <li class="mb-1">Ear Surgery – Otoplasty</li>
                  <li>Skin Dermabrasion</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-body.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Body</h4>
                <p class="mb-4">
                  Despite healthy lifestyles and diets, genetics play a large part in body fat distribution, breast size
                  and many other concerns. With aesthetic flair and internationally-trained techniques, Dr Marco is able
                  to provide patients with satisfactory results to achieve your desired figure.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Some of the common procedures for the body that Dr Marco
                  performs are:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Liposuction – Liposculpture</li>
                  <li class="mb-1">Tummy Tuck – Abdominoplasty</li>
                  <li class="mb-1">Repair The Rectus Diastasis</li>
                  <li class="mb-1">Buttock Augmentation – Gluteoplasty</li>
                  <li class="mb-1">Body Lift After Weight Loss</li>
                  <li class="mb-1">Endoscopic Surgery</li>
                  <li class="mb-1">Robotic Plastic Surgery</li>
                  <li class="mb-1">Female Genital Rejuvenation/Labiaplasty</li>
                  <li class="mb-1">Breast Augmentation/Breast Enhancement/Breast Implants</li>
                  <li class="mb-1">Breast Implant Removal/Breast Explantation</li>
                  <li class="mb-1">Breast Reduction – Mammoplasty</li>
                  <li class="mb-1">Breast Lift – Mastopexy</li>
                  <li class="mb-1">Nipple Reduction Surgery</li>
                  <li class="mb-1">Fat Grafting to Breast/Breast Augmentation With Fat</li>
                  <li>Renuvion® - Tighten & Firm Skin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctor />
    <SiteConsultation />
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteDoctor from '@/components/SiteDoctor.vue'
  import SiteConsultation from '@/components/SiteConsultation.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  export default {
    components: {
      SiteHeader,
      SiteDoctor,
      SiteConsultation,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-service {
    background-image: url(/images/bg-service.png);
  }
</style>